/** @format */

:root {
  --toastify-toast-width: 520px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Poppins;
  --toastify-font-weight: 400;
  --toastify-z-index: 9999;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #fff,
    #fff,
    #fff,
    #fff,
    #fff,
    #fff
  );
}

#jt-app {
  height: 100vh;
}
